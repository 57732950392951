* {
  box-sizing: border-box;
  outline: none !important;
}

input:focus, button:focus, a:focus {
  outline: none !important;
}

@font-face {
  font-family: 'Gotham-Book';
  src: local('Gotham'), url('./fonts/Gotham-Book.woff2') format('woff2'), 
}

body {
  background-color: #fff;
  font-family: Gotham,sans-serif,Helvetica,Arial;
}


#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#app.signIn {
  background-color: #fad1d6;
}

.left {
  text-align: left;
  align-items: flex-start;
}

.row {
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
}

.col.centre {
  align-items: center;
}

.row.separate {
  justify-content: space-between;
}

h2 select {
  font-size: 16px;
  font-weight: bold;
}
.row.center {
  align-items: center;
  justify-content: center;
}
.row.centre {
  justify-content: center;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  font-weight: 100;
}

h2 {
  font-weight: normal;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.outer {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.login-form {
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  padding: 20px;
  text-align: center;
  background-color: #fff;
}

.login-form li {
  text-align: left;
  margin: 4px 0px;
}

.login-form label {
  display: block;
  width: 100%;
  margin: 4px 0px;
}

.login-form label input {
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.login-form label input:focus {
  background-color: #fad1d6;
}

.login-form label span {
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  margin: 16px 2px 2px 4px;

}

.currentUser {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
  width: 100%;
}

.currentUser * {
  margin-bottom: 5px;
  text-align: center;
}

.currentUser h2, .currentUser h3 {
  font-size: 14px;
}

nav {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #fad1d6;
  color: #636569;
  padding: 0px 0px 8px 0px;
}

div.view-as {
  width: 100%;
  text-align: center;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  padding: 8px;
}


.nav-top {
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

nav .left, nav .right {
  width:200px;
}

.logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 0px 20px;
}
.logo h1 {
  font-size: 15px;
  letter-spacing: 3px;
  font-weight: 500;
  margin-top:-5px;
  text-align: center;
  color: #636569;
  
}

nav img {
  width: 160px;
  display: flex;
  
}

main {
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
}


main.signIn {
  color: #636569;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.logo img {
  display: flex;
  align-self: center;
  width: 200px;
}

.outer.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  max-width: none !important;
  text-align: center;
  background-color: #fad1d6;
}

.outer.signed-out {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  max-width: none;
  text-align: center;
  background-color: #fad1d6;
}

nav h1 {
  font-size: 40px;
  display: flex;
  justify-content: center;
}

nav ul {
  display: flex;
  margin: 0px;
  flex-wrap: wrap;
  justify-content: center;
}

nav li {
  margin: 3px;
}

a, a:link {
  color: #444;
}

a:hover, a:focus {
  color: #000;
  text-decoration: none;
}

nav li a, nav li a:link {
  display: flex;
  padding: 4px 10px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  text-decoration: none;
  color: #444;
}

nav li a.current, nav li a:hover.current, nav li a:focus.current {
  display: flex;
  padding: 4px 10px;
  background-color: #fff !important; 
  color: #333;
}

nav li a:hover, nav li a:focus {
  color: #333;
  background: rgba(255,255,255,0.5);
  outline: none !important;
}

i {
  margin: 0px 4px;
  color: #999;
}

main .outer > h1 {
  font-size: 24px;
  text-transform: uppercase;
  color: #636569;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}

main .outer > h2 {
  text-align: center;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

input, select {
  padding: 6px;
  margin: 2px;
  line-height: 20px;
  border: none;
  font-size: 14px;
  border-radius: 8px;
  background-color: rgb(254 243 245);
  color: #000;
}

textarea {
  margin: 2px;
  line-height: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgb(254 243 245) inset;
}

.loader {
  padding: 20px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: rgba(253, 94, 108, 1);
}

.file-upload-wrapper {
  display: flex;
  flex-direction: column;
  width: 430px;
  text-align: center;
  margin: 0 auto;

}


.file-upload::-webkit-file-upload-button {
  visibility: hidden;
}

label.file-upload-button + input {
  visibility: hidden;
}


label.file-upload-button {
  display: inline-block;
  border: none;
  border-radius: 8px;
  padding: 20px;
  text-transform: uppercase;
  font-size: 28px;
  line-height: 20px;
  margin: 0px 2px;
  color: rgba(253, 94, 108, 1);
  background-color: rgba(0,0,0, 0.03);
  cursor: pointer;
}

label.file-upload-button.disabled {
  cursor: not-allowed
}

label.file-upload-button:not(.disabled):hover, label.file-upload-button:not(.disabled):focus {
  color: rgb(219, 47, 61);
  background-color: rgba(0,0,0, 0.1);
  border: none;
}

textarea {
  padding: 6px;
  width: 100%;
  height: 80px;
  border: none;
  border-radius: 8px;
  background-color: rgb(254 243 245);
  color: #000;
}

input:focus, textarea:focus, select:focus {
  background-color: #fad1d6;
  outline: none !important;
  color: #000;
}

button {
  border: none;
  border-radius: 8px;
  padding: 4px 10px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  margin: 0px 2px;
  color: #333;
  background-color: rgba(0,0,0, 0.03);
  cursor: pointer;
}

a.button-in-button {
  display: inline-block;
  color: #000;
  padding: 0px 0px 0px 8px;
}

a.button-in-button:hover, a.button-in-button:focus  {
  display: inline-block;
  color: rgb(219, 47, 61);
  padding: 0px 0px 0px 8px;
}

button.sml {
  padding: 4px 6px;
  font-size: 12px;
  line-height: 12px;
  margin: 1px;
  border-radius: 5px;
}

.icon-order-1 {
  color:#333;
}

.icon-order-0 {
  color:rgba(253, 94, 108, 1)

}

.icon-order-0 svg, .icon-order-1 svg {
  display: inline-block;
  width: 15px !important;
}

.icon-imported {
  color:rgba(253, 94, 108, 1)
}

button:not(:disabled):hover, button:not(:disabled):focus {
  background-color: rgba(0,0,0, 0.1);
  border: none;
}

button:disabled {
  cursor: not-allowed;
  color: #bbb !important;
  background-color: #f7f7f7 !important;
}


input.search {
  width: 400px;  
  text-align: center;
}

div.search-outer input.search {
  width: 240px;  
  
}

div.specCardList {
  margin:0px 32px;
}

div.specCard {
  width: 100%;
  max-width: 820px;
  margin: 0px 0px 32px 0px;
  padding: 8px 0px;
  position: relative;
 }

 div.specCard h1 {
  font-size: 24px;
  font-weight: bold;
 }

 div.specCard.mini h1 {
  font-size: 24px;
  font-weight: bold;
 }

 div.specCard .ingredients-calc input {
  padding: 4px;
  margin: 0 2px;
  font-weight: bold;
  line-height: 14px;
}



 div.specCard.mini .ingredients-column {
  width: 35%;
  margin-right: 24px;
 }

 div.specCard.mini .steps-column {
  width: 65%;
 }


 div.specCard.mini .ingredients-column.size-sml {
  width: 50%;
 }

 div.specCard.mini .steps-column.size-sml {
  width: 50%;
 }

 

 div.specCard.mini button {
  display: none
 }

 div.ingredients-label {
  padding: 18px;
  max-width: 550px;
  line-height: 20px;
}

 div.specCard .sub {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  font-style: italic;
  justify-content: flex-start;
}

div.specCard .sub span {
  display: inline-block;
  width: 231px;
}

div.specCard .specCardHeading {
  display: flex;
  margin-top: 0px;
  align-items: center;
  border-top: 2px solid black;
}

div.specCard .specCardHeading .col1 {
  width: 100%;
}

div.photoWrapper {
  position: relative;
  width: 200px;
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  min-height: 110px;
}

div.custom-item div.photoWrapper {
  margin-left: 0px;
}

div.photo-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

div.photo-section div.photoWrapper {
  margin: 4px;
  width: 32%;
  display: flex;
}

.photo-fullsize {
  max-width: 80vw;
  max-height: 80vh;
  cursor: zoom-out;
}

div.photoWrapper input {
  visibility: hidden;
  height: 1px;
  padding: 0px;
}

div.photoWrapper label {
    border: none;
    border-radius: 8px;
    padding: 4px 10px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    cursor: pointer;
}



div.photoWrapper button {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

div.photoWrapper .photo {
  width: 100%;
  object-fit: contain;
  cursor:zoom-in;
}

div.specCard .multiplier {
  width: 80px
}
div.specCard-logo {
  border: none;
  width: 100%;
  margin: 0 !important;
  padding: 0 auto;
  border: none;
  text-align: center;
  position:relative;
}

div.specCard-logo img {
  width: 150px;
}

div.quantity {
  min-width: 70px;
  text-align: right;
  padding: 0px;
  font-weight: bold;
  margin-right: 8px;
  display:inline-block;
}

div.name {
  width: 100%;
}

div.indent {
  border-left: 1px solid #ccc;
  padding: 4px 0px 4px 8px;
  margin: 4px 0px 4px 16px;
  display: none;
}

div.indent.open {
  display: flex;
}

div.indent table {
  margin: 0px;
}

div.specCard .match {
  background-color: yellow;
}

td.no-wrap {
  white-space: nowrap;
}

div.specCard td {
  border: none;
  vertical-align: top;
  position: relative;
  padding: 0px 0px 8px 0px;
}

div.specCard div.ingredient-menu {
  position:absolute;
  right: 0px;
  top: 4px;
}

div.specCard table td {
  font-size: 16px;
}

div.specCard table.steps td:last-child {
  width: 100%;
}

div.specCard button {
  background-color: none;
  padding: 2px 8px;
}

div.specCardTop {
  width: 100%;
  margin: 0  !important;
  border: none !important;
  padding: 4px 0px  !important;
  display: flex  !important;
  flex-direction: column;
  align-items: flex-start;
}

div.specCard > div {
  margin: 8px 0px;
  padding-top: 8px;
}

div.specCard-terms {
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  margin: 0px 0px 4px 0px;
}

div.specCard h3 {
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0px 0px 6px 0px;
  position: relative;
}

div.specCard h3 .ingredients-menu {
  display: inline-block;
  position:absolute;
  right:0px;
}

div.allergen, div.suitable {
  display: inline-block;
  margin: 0px 2px 2px 0px;
  padding: 2px 5px;
  color: #000;
  font-weight: bold;
  border:1px solid #000;
  font-size: 12px;
  border-radius: 5px;
}

div.allergen {
  color:rgba(253, 94, 108, 1) !important;
  border-color:rgba(253, 94, 108, 1) !important;
}

div.allergens-list {
  margin-top: 4px;
}

div.allergens-pw h3 {
  color: #999;
}
div.allergens-pw div.allergen {
  color:#999  !important;
  border-color:#999  !important;
}

div.brand {
  width: 95%;
  border: 1px dashed #ccc;
  padding: 5px;
  margin: 3px;
}

div.brand h3 {
  width: 100%;
  font-weight: bold;
  font-size: 11px;
  
}

div.client-select-window {
  
  width: 460px;
  height: 230px;
  overflow-y: scroll;
}
div.client-select {
  display: inline-block;
  width: 49%;
  padding:4px;
}

.cost-toggle {
  display:flex;
  align-items: center;
  justify-content: center;
}

.cost-toggle input {
  width: 58px;
  text-align: right;
  padding: 6px;
  border-radius: 8px 2px 2px 8px;
  margin: 0px;
}

.cost-toggle input.has-value {
  background-color:rgba(253, 94, 108, 1) !important;
  color: white !important;
}

.cost-toggle input[disabled] {
  background-color: #f9f9f9;
  color: #999;
}

img.icon {
  width: 20px;
  height: 20px;
}

img.icon.scale-2 {
  width: 36px;
  height: 36px;
}

.cost-toggle button.icon {
  width: 32px;
  height: 32px;
  margin: 1px;
  border-radius: 2px 8px 8px 2px;
  text-align: center;
  padding: 0px;
}

.pink {
  color: rgba(253, 94, 108, 1);
}

button.icon:not(:disabled):hover, button.icon:not(:disabled):focus {
  background-color: rgba(253, 94, 108, 1);
  color: #fff;
}

button.selected {
  background-color: #fad1d6;
}

button.selected:not(:disabled), button.selected:not(:disabled):focus {
  background-color: rgba(253, 94, 108, 1);
  color: #fff;
}

div.days button:hover > span, div.days button:focus > span {
  color: #fff;
  border-top: 1px solid rgba(255,255,255,0.7);
}

button.key {
  padding: 20px;
  margin-bottom: 10px;
}

.important {
  color: rgba(253, 94, 108, 1);
}

button.inv {
  background-color: rgba(253, 94, 108, 1);
  color: #fff;
}

button.inv:not(:disabled):hover, button.inv:not(:disabled):focus {
  background-color: rgb(216, 53, 66);
  color: #fff;
  outline: none !important;
}

button.serious {
  background-color: #666;
  color: #fff;
}

button.serious:not(:disabled):hover, button.serious:not(:disabled):focus {
  background-color: #000;
  color: #fff;
  outline: none !important;
}

button.inv.order-1 {
  background-color: #333;
}



button.selected {
  background-color: rgba(253, 94, 108, 1);
  color: #fff;
}



button.large {
  font-size: 24px;
  padding: 12px 30px
}
button.large h2 {
  padding: 3px !important;
}

flex {
  display: flex;
  flex-direction: row;
}

flex div {
  padding: 10px;
  border:1px solid #ccc;
}

.table-wrapper {
  width: 100%;
}

table {
  border-collapse: collapse;
  margin: 0px auto 8px auto;
  font-size: 14px;
  width: 100%;
}

table tr.brand {
  background-color: #f9f5f5;
}

.table-wrapper > table {
  margin-bottom: 0px;
}

table.order {
  min-width: 80%;
}

thead.stickyTop {
  position:sticky;
  top: -1px;
  z-index: 100;
  background: #fff;
}

thead.stickyTop th {
  background-color:#f9f5f5;
  border:1px solid #ccc;
  box-shadow: inset 0px -2px 0px 0px #ccc;
}

td.stickyLeft, th.stickyLeft {
  position:sticky;
  left:0px;
  background-color: white;
  border:1px solid #ccc;
  box-shadow: inset -2px 0px 0px 0px #ccc;
}

thead.stickyTop th.stickyLeft {
  background-color:#f9f5f5;
  border:1px solid #ccc;
  box-shadow: inset -2px -2px 0px 0px #ccc;
}

td.lge {
  font-size:16px;
  font-weight: bold;
}

td.half {
  width: 50%;
}

td.top {
  vertical-align:top;
}

table.order.failed tr td {
  color: red;
}

td.border {
  border: 1px solid black !important; 
  padding: 8px;
}

th {
  padding: 8px;
  text-align: left;
  background-color:#f9f5f5;
  border:1px solid #ccc;
}

div.table-footer {
  border: 2px solid #000;
  max-width: 800px;
  margin: 24px auto 0px auto;
}

div.table-footer > h2 {
  text-align: center;
  padding: 8px;
}

div.table-footer h2 {
  font-weight: bold;
}

.custom-order, .additional-order, .special-instructions {
  border-top: 1px solid #000;
  margin: 0 auto 0 auto;
  padding: 16px;
  max-width: 800px;
  text-align: center;
}

div.delivery-note .custom-order {
  border: none;
}

.custom-items {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}

.custom-items table {
  width: 44%;
  margin: 18px 18px 0px 18px;
}

.custom-items table tr td:first-child {
  width: 30%;
  text-align: right;
  vertical-align: top;
}

.custom-items table tr td:last-child {
  width: 70%;
  font-weight: bold;
  vertical-align: top;
}

table.shop-grid {
  width: auto;
}

table.shop-grid th, table.shop-grid td {
  background-color: transparent;

  padding: 2px;
  text-align: center;
  margin:0 auto;
  border: none;
  
}

table.shop-grid th {
  padding: 8px 0px 8px 0px;
  position:relative;
}

table.shop-grid th .today {
  position:absolute;
  bottom: 0px;
  font-size: 12px;
  padding: 2px 4px;
  font-size: 10px;
  width: auto;
  border-radius: 3px;
  left: 50%;
  transform: translate(-50%, 0px);
}


table.shop-grid tr td:first-child,table.shop-grid tr td:last-child {
  border: none !important;
  min-width: 4px;
}

.shop-col {
  text-align: right !important;
  padding: 4px 8px 4px 0px !important;
  width: 270px;
}

.brand-col {
  text-align: left !important;
  padding: 8px 8px 0px 8px !important;
  border: transparent !important;
}

table.shop-grid tr:hover td:not(.brand-col) {
  background-color: #f9f5f5 !important;
}
table.shop-grid tr.highlighted td, table.shop-grid tr.highlighted:hover td {
  background-color: #fad1d6 !important;
}

table.shop-grid tr:hover td:first-child, table.shop-grid tr:hover td:last-child {
  background-color: #fff !important;
}

table.shop-grid tr.highlighted td:first-child, table.shop-grid tr.highlighted td:last-child {
  background-color: #fff !important;
}

table.shop-grid tr.highlighted td.selected {
  background-color: #fad1d6 !important;
}

table.shop-grid tr td.selected {
  background-color: #f9f5f5 !important;
}

table.shop-grid td {
  cursor: pointer;
  padding: 2px 0px;
  border: 1px solid rgba(0,0,0,0.2);
  display: table-cell;
  background-color:transparent;

  border: 1px solid rgba(0,0,0,0.2);
}

table.print-page > tbody > tr > td, table.print-page > tfoot > tr > td, table.print-page > thead > tr > th {
  border: none;
  font-weight: normal;
  padding: 0px;
}


table.print-page thead > tr > th {
  padding-bottom: 20px;
  margin-bottom: 20px !important;
  border-bottom:2px solid black;
}

table.print-page {
  font-size: 14px;
}


table.print-page tbody {
  width: 100%;
  
}


table.print-page div.empty-footer {
  height: 120px;
  width: 100%;
}


table.print-page th {
  background-color: transparent;
}



table.clear td {
  border: none;
}

td {
  text-align: left;
  padding: 3px 8px;
  border:1px solid #ccc;
}

tr.total-row td {
  font-weight: bold;
  background-color: #f9f5f5;
  padding: 16px 8px;
}

td.right, th.right, div.right {
  text-align: right;
}

td.center, th.center, div.center, h4.center, h3.center {
  text-align: center;
}

td.nowrap, th.nowrap {
  white-space: nowrap;
}
div.data-row {
  padding: 4px;
  border-bottom:1px solid #000;
}

tr.inactive td {
  color:#999;
}
/* order */

span.someOrders, span.noOrders {
  color: #666;
  font-size: 12px;
  width: 100%;
  display: inline-block;
  margin: 8px 0px;
  padding: 0px;
}

span.someOrders {
  font-weight: bold;
}

span.someOrders img {
  height: 16px;
  vertical-align: middle;
}

span.someOrders.future {
  color: rgba(253, 94, 108, 1);
}

div.noOrders {
  opacity: 0.6;
}



.selected span {
  
}

div.order-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
}

div.invoice-total-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
}

div.invoice-total-row.total {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

div.invoice-total-row  input {
  text-align: right;
}

div.total-row {
  margin-top: 20px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  
}

div.check-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

div.check-list label:focus-within {
  background-color: #fad1d6;
}
div.check-list label {
  display: flex;
  cursor: pointer;
  width: 32%;
  justify-content: space-between;
  border: 1px solid #fad1d6;
  color: #999;
  padding: 4px;
  margin: 3px;
  border-radius: 5px;
}

div.check-list label.checked {
  font-weight: bold;
  color:rgba(253, 94, 108, 1) !important;
  border-color:rgba(253, 94, 108, 1) !important;
  
}


div.total-row input:first-child {
  font-weight: bold;
  padding-left: 0px;
  font-size: 20px;
}

div.order-row.zero {
  max-height: 50px; 
  overflow: hidden;
}

div.order-locked div.order-row.zero {
  max-height: 0;
}

div.order-locked div.photoWrapper {
  background-color: transparent
}

div.order-locked div.photoWrapper label, div.order-locked div.photoWrapper button {
  display: none
}

div.order.invoice {
  position:relative;
}

div.order .voided-banner {
  position: absolute;
  color: rgba(0,0,0,0.2);
  font-size: 240px;
  font-weight: bold;
  width: 100%;
  text-align:center;
  left: 50%;
  transform: rotate(-15deg) translate(-50%);
  z-index: 1000;
}

div.order.invoice .custom-item-desc {
  padding: 2px 200px 8px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom:1px solid #000;
}

div.order.invoice .custom-item-desc div {
  width: 24%;
  font-size: 10px;
  text-transform: uppercase;
  padding: 4px;
}

div.order.invoice .custom-item-desc div strong {
  text-transform: none;
  font-size: 13px;
}

div.order.invoice select {
  background-color: #fff !important;
    text-align: right;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

div.order.invoice h1 {
  text-align: left;
  align-items: flex-start;
  margin:0px;
  padding:0px;
}

div.campaignWrapper {
  width: 100%;
  background: #fad1d6;
}

div.campaign {
  max-width: 600px;
  margin:0 auto;
}

div.campaign input:focus, div.campaign textarea:focus {
  background-color: #fff;
}

div.campaign a, a:link, a:hover, a:focus {
  color: #333;
}


div.campaign ol li {
  
}

table.customerForm {
  width: 300px;
}

table.customerForm td {
  border: none;
  padding-top: 20px;
}

table.orders-list tfoot {
  font-weight: bold;
  font-size: 16px;
}

table.orders-list tfoot td {
  padding-top: 20px;
  background-color: #f9f5f5;
}

table.production-sheet td.category {
  font-size: 18px;
  font-weight: bold;
  border-top: 2px solid black;
}

table.production-sheet td {
  font-size: 20px;
}

table.production-sheet td:first-child {
  font-size: 14px;
}

table.production-sheet td:last-child, table.production-sheet th:last-child {
  border-left: 2px solid black;
}

div.order.invoice td.category {
  border: none !important;
}

div.order.invoice td.category h3 {
  display: none !important;
}

div.order.invoice .invoice-details td {
  padding-left: 0px;
}

div.order td.category {
  border-bottom: 1px solid black;
}

div.order td.category table {
  margin: 0px;
}
div.order td.category table td {
  padding: 0px;
}

div.order {
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 20px 2px;
  /*box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(0, 0, 0, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;*/
  background-color: #fff;
  max-width: 820px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 30px;
}

div.order h4 {
  font-size: 16px;
  font-weight:bold;
  margin-bottom: 16px;
}

div.order.order-locked .custom-item select {
  padding: 0px;
  margin: 0px;
} 

div.order.order-locked .custom-item input, div.order.order-locked .custom-item textarea {
  padding: 0px;
  margin: 0px;
}

div.order .custom-item {
  width: 100%;
  border-bottom: 2px solid #000;
  padding: 8px 0px;
  position: relative;
}

div.order .custom-item .remove-button {
  position: absolute;
  right: 0px;
  top: 16px;
}

div.order .custom-fields table {
  max-width: 500px;
  width: 100%;
}

div.order .custom-fields table tr td:first-child {
  width: 30%;
}

div.order .custom-fields table select {
  font-weight: bold;
  text-align: right;
  width: 100%;
}

div.order.order-locked .custom-fields table select {
  font-weight: bold;
  text-align: right;
  background-color: #fff !important;  
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 1;
  text-indent: 1px;
  text-overflow: '';
  -webkit-text-fill-color: #000;
}

div.order .custom-fields .right {
  font-weight: bold;
}

div.order .custom-fields table input:not([type="radio"]) {
  width: 100%;
}

div.order .custom-fields table textarea {
  width: 100%;
  height: 70px;
}

div.order textarea {
  max-width: 500px;
}


div.order .button-list button {
  white-space: nowrap !important;
}

div.order h1 {
  font-size: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top:  20px;
  text-transform: uppercase;
  justify-content: center;
}

div.order h3 {
  margin: 0px;
  font-size: 20px;
  line-height: 22px;
  padding: 8px 0px 8px 0px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
}

div.order h1 span {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
}

div.order.invoice .invoice-footer {
  font-size: 12px !important;
  margin: 20px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
  width: 100%;
  bottom: 0px;
  text-align:center;
}


div.order .forDelivery {
  text-align:center;
  padding: 10px 0px 0px 0px;
}

div.order .orderNumber {
  text-align:center;
  padding: 0px 0px 10px 0px;
}

div.order .order-header {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.order .order-header input {
  width: 180px;
  text-align: center;
}

input.right {
  text-align: right;
}

div.order input.cost {
  padding: 8px 0px 8px 2px;
  width: 72px;
  text-align: right;
}

div.order input.o-cost {
  color: #000;
  font-weight: bold;
}

div.order input.p-cost {
  color: #ccc;
}

div.order input.s-cost {
  color: #000;
}

.pre-line {
  white-space: pre-line;
}

.pre {
  white-space: pre;
}

div.order input.total-cost {
  font-weight: bold;
}

input:disabled, textarea:disabled, input:disabled::placeholder, textarea:disabled::placeholder {
  -webkit-text-fill-color: #000; /* 1. sets text fill to current `color` for safari */
  opacity: 1; /* 2. correct opacity on iOS */
}

div.order input[disabled], div.order textarea[disabled] {
  background-color: #fff;
  color: black;
}

div.order.order-locked textarea.special[disabled] {
  text-align:center;
}

.status-token {
  text-transform: uppercase;
  font-size: 12px;
  white-space: nowrap;
  padding:2px 3px;
  border-radius: 5px;
}

.status-token.old {
  color: #666;
}

.status-token.baking {
  color: rgba(253, 94, 108, 1);
}

div.status {
  background-color: #fff;
  color: rgba(253, 94, 108, 1);
  border-radius:8px;
  padding: 6px 12px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 2px dashed #ccc;
}

div.status div {
  display: flex;
  align-items: center;
}

div.sml, td.sml, th.sml, span.sml {
  font-size: 11px;
  font-weight: normal;
}

div.status div.left {
  font-size:18px;
  font-weight:bold;
  position: relative;
  justify-content: flex-start;
  width: 45%;
}
div.status div.left > svg {
   margin-right:15px;
   width: 28px;
}

div.status div.left button {
  position: absolute;
  left: -24px;
  top: 57px;
}

div.status div.locked-status {
  text-align: center;
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  margin:0 auto;
  left: 50%;
  justify-content: center;
  transform: translate(-50%);
}

div.status div.right {
  font-size:14px;
  width:45%;
  text-align: right;
  justify-content: flex-end;
}
div.status div.right b {
  font-size: 18px;
}
div.status div.right svg, div.status div.right img {
  margin-left:15px;
}



div.order div.status h2 {
  padding: 5px;
}
div.status h4 {
  font-size: 14px;
}

div.status.delivered {
  background:rgba(253, 94, 108, 1);
  color:#fff;
  border: 2px solid rgba(253, 94, 108, 1);;
}

div.status.custom.delivered {
  background:#333;
  color:#fff;
  border: 2px solid #333;

}

div.status.processing {
  border: 2px dashed #999;
}

div.status.new {
  border: 2px dashed #999;
}

div.status.new.custom {
  border: 2px dashed #999;
  color: #333;
}

div.status.voided {
  border: 2px solid #eee;
  color: #666;
  background-color: #f4f4f4;
}


div.order-inner {
  padding: 0px;
}

option.inactive {
  color: #999;
}

option.inactive:before {
  content: ">";
  font-size: 20px;
  display: none;
  padding-right: 10px;
  padding-left: 5px;
  color: #fff;
}

div.order i {
  width:100%;
  margin-top:15px;
}

div.order h2 {
  padding: 10px 0px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}


.thumb {
  display: flex;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: rgb(254 243 245);
  border-radius: 8px;
  margin: 1px;
}

.thumb:hover, .thumb:focus {
  background-color: #fad1d6;
}

.thumb img {
  max-width: 90%;
  max-height: 90%;
  border: 1px solid #fff;
}

.large-image {
  display: flex;
  width: 600px;
  height: 600px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.large-image img {
  max-width: 100%;
  max-height: 100%;
}

/* overrides */


.mdl-button--raised.mdl-button--colored, .firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color:  rgba(253, 94, 108, 1) !important;
}


.firebaseui-link {
  color: #333 !important;
}



div.react-calendar {
  border-radius: 8px;
  border: none;
  width: 400px;
  padding: 0px;
  background-color: transparent;
}

div.react-calendar button {
  font-size: 18px;
  border: 3px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 10px !important;
  
}

h4.copying-message {
  text-align: center;
  padding: 8px 0px;
  font-weight: bold;
}

div.react-calendar button abbr {

}

div.react-calendar button b {
  background-color: #fff;
  color: #000;
  padding: 2px;
  font-size: 11px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
}

div.react-calendar button.has-orders b {
  visibility: visible;
}

abbr[title] {
  text-decoration: none !important;
}

.react-calendar__navigation {
  height: auto !important;
  margin-bottom: 8px !important;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0px !important;
}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: rgba(253, 94, 108, 1) !important;
  color: #fff  !important;
  outline: none !important;
}

button.react-calendar__tile:enabled:hover, button.react-calendar__tile:enabled:focus, button.react-calendar__tile--active, button.react-calendar__tile--active.past, button.react-calendar__tile--active.react-calendar__tile--now.past {
  background-color: rgba(253, 94, 108, 1) !important;
  color: #fff  !important;
  outline: none !important;
  border: none !important;
}

.react-calendar__tile--active {
  filter: none !important;
}

.react-calendar__tile:disabled, .react-calendar__navigation button[disabled] {
  background-color: #fff !important;
}

.react-calendar__tile {
  background-color: #f9f5f5 !important;
}

.react-calendar__tile--hover {
  background-color: #fad1d6 !important;
}

.react-calendar__tile--now {
  background-color: #fad1d6 !important;
}

div.calendar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 20px 8px 20px;
  margin: 0px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

button.day strong {
  font-size: 30px;
  line-height:28px;
  opacity:0.6;
}


.cutoff {
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-weight: normal;
  border-radius: 16px;
  margin: 0px 0px 16px 0px;
  padding: 8px 32px;
  position: relative;
}


.cutoff::after {
  position: absolute;
  content:'';
  width: 0px; 
  height: 0px; 
  bottom: -8px;
  left: 50%;
  transform: translate(-50%,0);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  
  border-top: 8px solid white;
}

.cutoff .date {
  text-align: center;
  font-weight: bold;
  background: #f9f5f5;
}

.cutoff span {
  background: rgba(255,255,255,0.4);
  display: inline-block;
  padding: 4px 10px;
  margin: 15px;
  border-radius: 4px;
}

nav button {
  background: rgba(0,0,0,0.03);
  color: #333;
}

nav button:hover, nav button:focus {
  background: rgba(0,0,0,0.1);
  color: #333;
}

nav.admin-menu {
  padding: 3px;
  background: #e8c1c6;
  overflow: hidden;
}


a.order-link {
  color: #000;
  text-decoration: none;
}

a.order-link:hover, a.order-link:focus {
  color: rgba(253, 94, 108, 1) !important;
  text-decoration: underline;
}


nav.admin-menu li a {
  color: #444;
}

nav.admin-menu li a:hover, nav.admin-menu li a:focus {
  background: rgba(255,255,255,0.5);
  color: #222;
}

button.clear, a.clear {
  background: rgba(0,0,0,0.03);
  color: #333;
}

button.clear:not(:disabled):hover, button.clear:not(:disabled):focus {
  background: rgba(0,0,0,0.1);
  color: #333;
  outline: none !important;
}

button.light, a.clear {
  background: #fff;
  color: #333;
}

button.light:not(:disabled):hover, button.light:not(:disabled):focus {
  background: rgba(255,255,255,0.8);
  color: #333;
  outline: none !important;
}

button.day:not(:disabled):hover, button.day:not(:disabled):focus, button.day.we:not(:disabled):hover, button.day.we:not(:disabled):focus {
  background-color: rgba(253, 94, 108, 1);
  color: #fff !important;
  outline: none !important;
}

button.day svg {
  margin-bottom: 4px;
}

button.day:hover span, button.day:focus span {
  color: #fff !important;
}
button.day:hover strong, button.day:focus strong {
  opacity:0.7;
}

button.day {
  display: flex;
  padding: 6px 2px 6px 2px;
  position: relative;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items:center; 
  background: #fad1d6;
  color: rgba(253, 94, 108, 1);
  margin: 0px 1px;
  /*transition: all 0.2s ease;*/
  width: 48px;
  min-height:70px;
}



button.day.wide {
  width: 64px;
}

button.day b {
  font-size: 11px;
  line-height: 11px;
  opacity:0.6;
}

.calendar-menu {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: normal;
  justify-content: space-between;
  align-items: flex-start;
  width: 270px;
  height: 125px;
  padding: 14px 0px 21px 0px !important;
  color: #666;
  font-style: italic;
}

.calendar-menu button {
  margin: 2px 4px;
  font-size: 12px;
  padding: 5px 10px;
  align-items: center;
}

.calendar-menu .left {
  margin-right: 20px;
}

.selected button.day, .selected button.day.we {
  background: rgba(253, 94, 108, 1);
  color: white;
  transform: scale3d(1.3,1.3,1.3);
  margin: -3px 12px 12px 12px;
}

.selected button.day b, .selected button.day.we b {
  opacity: 1;
}

.selected button.day strong, .selected button.day.we strong {
  opacity: 0.6;
}

.selected button.day::after {
  position: absolute;
  content:'';
  width: 0px; 
  height: 0px; 
  bottom: -6px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(253, 94, 108, 1);
}

button.day.we {
  background: #f8e6e7
}

button.day .cut-off-button {
  position:absolute;
  width: 16px;
  padding: 1px;
  border-radius: 50%;
  height: 16px;
  top: 4px;
  right: -8px;
  background-color:rgba(253, 94, 108, 1);
}

button.day .cut-off-button:hover, button.day .cut-off-button:focus {
  background: #000;
}

button.day .cut-off-button svg {
  color: #fff;
}

div.days {
  width: 100%;
  display: flex;
  justify-content: center;
  position:relative;
  padding: 0px;


  background: #fff;
}

div.days .table-wrapper {
  outline: none !important;
  padding: 20px 0px;
}

.calendar-btn {
  position: absolute;
  right: 0;
}


div.days > b {
  margin-top:4px;
  font-size:12px;
}

div.group {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 12px;
}

div.cutoff-warning {
  color: #cc0000;
  padding: 4px;
  font-size: 13px;
}

div.cutoff-ok {
  color: #333;
  padding: 4px;
  font-size: 13px;
}

div.group-name {
  padding: 2px 16px;
  font-size: 12px;
  position: absolute;
  top: -8px;
  background: #fff;
  color: #999;
  font-weight: bold;
  margin: 0 auto;
  text-transform: uppercase;
}

ul.button-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  flex-wrap: wrap;
  justify-content: center;
}

ul.button-list li {
  margin: 3px;
  display: flex;
  align-items: center;
}


ul.button-list li.highlighted button {
  background-color: #fad1d6;
}

ul.button-list li.selected button {
  background-color: rgba(253, 94, 108, 1);
  color: #fff;
  outline: none !important;
}

.light {
  color: #aaa;
}

.highlight {
  background-color: rgb(254 243 245);
}

.temporary {
  padding: 8px;
  font-weight: bold;
  height:20px;
}

/* firebaseui overrides */

.firebaseui-card-footer {display: none}


/* modal */

/* modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #713e44;
  opacity: .7;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper + .modal-overlay {
  z-index: 1060;
}

.modal-wrapper + .modal-overlay + .modal-wrapper {
  z-index: 1070;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 0px auto;
  border-radius: 3px;
  padding: 0px;
  cursor: default;
  max-width: 90vw;
  max-height: 90vh;
  overflow: scroll;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  background: #fad1d6;
  padding: 12px;
  align-items: center;
}

.modal-header.minimum {
  padding: 0px;
  position: relative;
}

.modal-header.minimum h1 {
  display: none;
}

.modal-header.minimum button {
  position: absolute;
  right: 0px;
  top: -50px;
}

.modal-header h1 {
  margin: 0;
}

.modal-body {
  padding: 16px;
}

.modal > h1 {
  text-align: center;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
}




div.delivery-note {
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    background-color: #fff;
    width: 80%;
    margin: 0 auto 48px;
    padding: 20px;
    page-break-after: always;
    position: relative;
}

div.delivery-note-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.delivery-note-top .details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20px;
  text-align: left;
}

div.delivery-note-top .left {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

div.delivery-note-top .right {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

div.delivery-note-top .right h2 {
}

div.delivery-note-top .details h3 {
  font-weight: normal;
  margin: 0px;
}


div.delivery-note > table {
  margin: 16px auto;
  max-width: 90%;
}

div.delivery-note:last-child {
  page-break-after: none !important;
}

.delivery-note h3 {
  text-align: center;
  margin: 24px 0px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.delivery-note h2 {
  text-align: center;
  font-size: 21px;
  text-transform: none;
  margin-bottom: 8px;
}

div.delivery-note h4 {
  text-align: center;
  padding: 4px;
}

div.delivery-note h4 b {
  font-weight: 600;
  letter-spacing: 2px;
  display: block;
  text-transform: uppercase;
  margin-bottom: 6px;
}

div.delivery-note h1 {
  font-size: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 4px;
  text-transform: uppercase;
  justify-content: center;
}

div.delivery-note h1 span {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
}


div.delivery-note td.category {
  font-weight:bold;
  font-size: 16px;
  padding-top: 5px;
}

div.delivery-note table.page table tr.cat {
  border-top: 2px solid #000;
}

div.delivery-note table.page table tr:last-child {
  border-bottom: 2px solid #000;
}

div.delivery-note td {
  font-size: 12px;
  padding: 3px 8px;
  border-left: none;
  border-right: none;
}

div.delivery-note td.quantity {
  text-align:right;
  padding: 3px 32px;
}

div.delivery-note .logo {
  width: 160px;
  background-color: #fad1d6;
  padding: 13px 13px;
  margin: 0 auto;
}

div.delivery-note .logo img {
  width: 130px
}

div.delivery-note .logo h1 {
  font-size: 15px;
  letter-spacing: 3px;
  font-weight: 500;
  margin-top:-5px;
  text-align: center;
  color: #636569;
}

div.delivery-note .footer {
  padding-top: 30px;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  border: none;
}

div.delivery-note .fixed-footer {
  position: absolute;
  bottom: 0;
  padding: 0;
  width: 100%;
  padding: 30px 0px 0px 0px;
  margin-left: -20px;
  height: 100px;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  border: none;
}

div.delivery-note .spacing-for-footer {
  height: 100px; 
  vertical-align: top;
  text-align: center;
  background: white !important;
  border: none !important;  
}

div.delivery-note th {
  background: white !important;
  border: none !important;
  padding:1;
}


.print-only {
  display: none;
}



form#auth-sign-up button {
  background-color: #111;
}

form#auth-sign-up button span::after {
  content: ' AS A NEW USER'
}


.order-shop-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}





@media only screen and (min-width: 980px) {
  .order-shop-list {
    flex-direction: row;
    max-width: 980px;
    margin: 0 auto;
  }

  .table-wrapper.max {
    max-width: 980px;
    margin:0 auto;
  }

  div.specCard {
    min-width:800px;
  }
  
}



@media print {

  @page {

    size: A4;

    @bottom-center {
      content: 'Page ' counter(page) ' of ' counter(pages);
    }
  }

  body.no-print-root #root {
    display: none;
  }

  .print-only {
    display: block;
  }

  .modal-overlay {
    display: none;
  }

  .modal-header {
    display: none
  }
  

  .modal-wrapper {
    position: relative;
    width: 100%;
    min-height: auto !important;
    max-height: auto !important;
    height: auto !important;
    overflow: visible;
    overflow-y: visible;
  }

  .modal {
    max-height: auto;
    max-width: 100vw;
    min-height: 100vh;
    overflow: visible;
    margin: 0px;
    padding: 0px;
  }

  body {
    width:100%;
    height:100%;
    padding: 0px;
    box-sizing: border-box;
  }

  body * {
    color: black !important;
  }

  div.outer {
    padding: 0px;
  }

  table.print-page {
    width: 100%;
    height: 100%;
  }


  div.invoice-total-row {
    padding: 10px 0px;
  }


 


  
  .no-print {
    display: none !important;
  }

  body, h1, h2, h3, h4, h5, h6 {
    background-color: white !important;
    color: black !important;
  }

  div.order {
    padding: 0px;
    
    box-shadow: none !important;
    border: none !important;
    width: 100% !important;
    page-break-after: always;
    margin: 0px !important;
  }

  div.order.invoice .invoice-footer {
    position: fixed;
    height: 120px;
    width: 100%;
  }


  div.order.invoice {
    border: none !important;
  }


  div.order.invoice table {
    width: 100% !important;
  }

  
  div.order .voided-banner {
    color: rgba(0,0,0,0.2) !important;
  }

  div.order.invoice input {
    padding:0px;
    margin: 2px 0px;
  }

  div.order-row.zero {
    display: none;
  }

  main .outer > h1 {
    font-size:20px;
  }
  

  select.shop-filter, select {
    background-color: #fff !important;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  select.shop-filter a {
    display: none;
  }

  tr, td, th {
    font-size: 11px;
    padding: 2px !important;
  }


  .table-wrapper {
    width: 100% !important;
    overflow:visible;
    outline: none !important;
  }


  nav, div.calendar, .button-list, button, .group, .group-name {
    display: none !important;
  }

  div.delivery-note {
    box-shadow: none !important;
    background-color: #fff;
    width: 100% !important;
    margin: none !important;
    padding: 0 !important;
    margin-bottom: 50px !important;
  }


  div.delivery-note table {
    break-inside: auto;
  }

  div.delivery-note div.logo, div.delivery-note div.logo * {
    background-color: #fad1d6 !important;
  }

  div.delivery-note div.logo h1 {
    color: #636569 !important;
  }

  div.delivery-note td.category {
    font-weight:bold;
    font-size: 16px;
    padding-top: 5px;
  }
  
  div.delivery-note .fixed-footer {
    position: fixed;
    border: none !important;
    background-color: white !important;
  }

  div.specCardList {
    margin:0px !important;
  }

  div.specCard {
    box-shadow: none;
    width: 100%;
    max-width: auto;
    position: relative;
    page-break-inside: avoid;
    page-break-after: always;
    padding: 0px !important;
    margin: 0px !important;
  }

  div.specCard.mini {
    page-break-inside: auto;
    page-break-after: auto;
  }



  div.specCard .ingredients-calc input {
    padding: 0px;
  }

  div.specCard .match {
    background-color: none !important;
  }

  div.specCard label {
    display: none;
  }

  div.specCard input.multiplier {
    background: white;
    font-weight: bold;
  }

  div.photoWrapper {
    display: none !important;
  }

  div.photoWrapper.photoShown {
    display: flex !important;
  }





  div.indent.open {
    display: none !important;
  }


}





.sbui-auth {
  max-width: 360px;
  margin: 0 auto;
}

a.sbui-typography-link {
  color: #636569 !important;
}

button.sbui-btn-primary, button.sbui-btn-primary:hover {
  background-color:rgba(253, 94, 108, 1)
}

.sbui-input, .sbui-input:focus {
  background-color: #fff !important;
  border-color: #999 !important;
}

.sbui-typography-text {
  font-weight: bold;
  font-size: 20px !important;
  line-height: 24px !important;
}